export default function (doms){
   // 判断如果底部区域进入可视区域就给动画
   const observer = new IntersectionObserver((entrys) => {
    let dom_ = entrys[0].target
    let className = dom_.className
    for (const v of entrys) {
      if (v.isIntersecting) {
        if (className == 'zhuti_top') {
           dom_.children[0].style.transform = 'translate(0,0)'
           dom_.children[1].style.transform = 'translate(0,0)'
           dom_.children[2].style.transform = 'translate(0,0)'
           dom_.children[0].style.opacity = 1
           dom_.children[1].style.opacity = 1
           dom_.children[2].style.opacity = 1
        } 
        else if (className == 'zhuti_middle') {
          dom_.children[0].style.transform = 'translate(0,0)'
          dom_.children[1].style.transform = 'translate(0,0)'
          dom_.children[0].style.opacity = 1
          dom_.children[1].style.opacity = 1
        } 
        else if (className == 'zhuti_bottom') {
          dom_.style.transform = 'translate(0,0)'
          dom_.style.opacity = 1
        }
        else {
          dom_.style.transform = 'translate(0,0)'
          dom_.style.opacity = 1
        }
      } else {
        if (className == 'zhuti_top') {
          dom_.children[0].style.transform = 'translate(-60px,60px)'
          dom_.children[1].style.transform = 'translate(0,60px)'
          dom_.children[2].style.transform = 'translate(60px,60px)'
          dom_.children[0].style.opacity = 0
           dom_.children[1].style.opacity = 0
           dom_.children[2].style.opacity = 0
        } else if (className == 'zhuti_middle') {
          dom_.children[0].style.transform = 'translate(-60px,60px)'
          dom_.children[1].style.transform = 'translate(60px,60px)'
          dom_.children[0].style.opacity = 0
          dom_.children[1].style.opacity = 0
        } else if (className == 'zhuti_bottom') {
          dom_.style.transform = 'translate(0,60px)'
          dom_.style.opacity = 0
        } else {
          dom_.style.transform = 'translate(0,60px)'
          dom_.style.opacity = 0
        }
      }
    }
  }, { root: null, rootMargin: '0px', threshold: 0.15 });
  observer.observe(doms); // 观察的目标元素
}