<template>
<div class="xyxw">
            <h1 @click="home_(zXdata.id)">{{zxList.title}} <a>MORE></a></h1>
            <div class="card" v-if="type == 1" @click="home_item_(props.zXdata.item[0])">
              <p>
                <span>{{zxList.item[0]?zxList.item[0].created_at.slice(8,10):''}}</span>
                <a>{{zxList.item[0]?zxList.item[0].created_at.slice(0,7):''}}</a>
              </p>
              <section>{{zxList.item[0].title}}</section>
            </div>
            <ul>
              <li v-for="(item,i) in type == 1?zxList.item.filter((x,y) => y !== 0):zxList.item" :key="i"  @click="home_item_(item)"><img src="@/assets/icon2.png"><span>{{item.title}}</span><a>{{item.created_at.slice(0,10)}}</a></li>
            </ul>
          </div>  
</template>

<script setup>
import {ref,reactive,defineProps,watch} from 'vue'
const props = defineProps({
  type:Number,
  zXdata:Object
})
const zxList = ref({})
watch(() => props.zXdata,() => {
  zxList.value = props.zXdata
},{immediate:true,deep:true})

const emits = defineEmits(['home_header','home_item'])
const home_ = (idd) => {
  emits('home_header',idd)
}
const home_item_ = (item) => {
  emits('home_item',item)
}
</script>

<style lang="less" scoped>
@maxw:1020/100vw;
@maxw_:750/100vw;
.xyxw{
          >h1{
              margin: 30/@maxw 0 0 0;
              padding: 0 0 30/@maxw 0;
              width: 100%;
              height: 60/@maxw;
              border-bottom-style: solid;
              border-bottom-width: 4/@maxw;
              border-image: linear-gradient(90deg, #174994 0%, #387DE2 100%) 4;
              font-size: 42/@maxw;
              font-family: fantBold;
              font-weight: 300;
              color: #174994;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              justify-content: space-between;
              transition: ease .3s;
              transform-style: preserve-3d;
              >a{
              float: right;
              font-size: 26/@maxw;
              font-family: Source Han Sans CN-Medium;
              font-weight: 500;
              color: #B19A86;
              line-height: 60/@maxw;
              transition: ease .3s;
          }
          }
          >ul{
            margin: 30/@maxw 0 0 0;
            padding: 0;
            li{
              margin-top: 10/@maxw;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              >img{
                vertical-align: middle;
                width: 9/@maxw;
                height: 16/@maxw;
                margin-right: 10/@maxw;
              }
              >span{
                flex: 1;
                font-size: 26/@maxw;
                font-family: Source Han Sans CN-Medium;
                // font-weight: 300;
                color: #292929;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
              >a{
                width: 130/@maxw;
                white-space: nowrap;
                font-size: 22/@maxw;
                font-family: Source Han Sans CN-Medium;
                // font-weight: 300;
                color: #5F5F5F;
              }
            }
          }
          .card{
            position: relative;
            margin-top: 30/@maxw;
            width: 100%;
            height: 130/@maxw;
            background: url("../assets/3109163a4904e0867561b8921adf9c4.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 0 0 -6/@maxw;
            padding: 0;
            width: 130/@maxw;
            height: 100%;
            color:#d7b497;
            >span{
              font-size: 55/@maxw;
              font-family: Source Han Sans CN-Medium;
              font-weight: 500;
              color: #D1BAA6;
            }
            >a{
              margin-top: -10/@maxw;
              margin-bottom: 10/@maxw;
              font-size: 19/@maxw;
              font-family: Source Han Sans CN-Medium;
              font-weight: 500;
              color: #D1BAA6;
            }
            }     
            >section{
              box-sizing: border-box;
              padding: 25/@maxw;
              padding-left:35/@maxw;
              padding-right:30/@maxw;
              flex: 1;
              height: 100%;
              font-size: 26/@maxw;
              font-family: Source Han Sans CN-Medium;
              font-weight: 500;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
            >section::before{
                  position: absolute;
                  top: 28%;
                  left: 125/@maxw;
                  content: '';
                  width: 2/@maxw;
                  height: 43/@maxw;
                  background-color: #fff;
            }
          }
}
@media screen and (max-width:750px) {
  .xyxw{
    margin: 56/@maxw_ 0 !important;
  }
  .xyxw{
      >h1{
        margin-top: 30/@maxw_ !important;
        font-size: 42/@maxw_ !important;
        border-bottom-width: 5/@maxw !important;
        padding-bottom: 40/@maxw_ !important;
        >a{
        font-size:26/@maxw_ !important;
      }
      }
      >ul {
        padding-top: 1/@maxw_ ;
        >li{
        margin-top: 20/@maxw_ !important;
        >span{
          margin-left: 12/@maxw_;
          font-size: 30/@maxw_ !important;
      }
      >a{
        font-size: 22/@maxw_;
        margin-right: 20/@maxw_;
      }
      }
      }
    }
    .card{
      margin-top: 30/@maxw_ !important;
      >p{
        >span{
          font-size: 48/@maxw_ !important;
        }
        >a{
          font-size: 16/@maxw_ !important;
        }
      }
       >section{
        font-size: 22/@maxw_ !important;
        line-height: 32/@maxw_;
    }
    }
}
</style>
