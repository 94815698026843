<template>
  <div class="keyue_box">
    <header @click="push_kyzs(kyzs_data)">{{ kyzs_data.title }} <a>MORE></a></header>
    <ul ref="poUl">
      <li v-for="(item1, ii) in kyzs_data.item" :key="ii" @click="edit_xyd(ii)"></li>
    </ul>
    <el-carousel class="el_car" ref="el_car" indicator-position="none" @change="poChange">
      <el-carousel-item v-for="(item, i) in kyzs_data.item" :key="i">
        <div class="role">
          <section class="role_one" v-for="(item1,ii) in item" :key="ii" @click="kyzs_item_(item1)">
            <div class="leftImg"><img :src="baseUrl + item1.thumb" alt="" v-if="item1.thumb"/></div>
            <div class="nav">
              <i></i>
              <p>{{ item1.author }}：</p>
              <span>{{ item1.description }}</span>
              <a>{{ item1.created_at ? item1.created_at.slice(0, 10) : '' }}</a>
            </div>
          </section>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template> 
<script setup>
import { ref, reactive, watch, onMounted, defineProps, defineEmits } from "vue"
const emits = defineEmits(['kyzs_header', 'kyzs_item'])
const props = defineProps({
  data_: Object
})
const baseUrl = process.env.VUE_APP_BASE_URL
const kyzs_data = ref(props.data_)
const poUl = ref(null)
onMounted(() => {
  watch(() => props.data_, (n, o) => {
    kyzs_data.value = props.data_
  }, { deep: true, })
  poUl.value.children[0].style.backgroundColor = '#B19A86'
})

let poChange = (e) => {
  const ul = poUl.value.children;
  for (let i = 0; i < ul.length; i++) {
    ul[i].style.backgroundColor = ''
  }
  ul[e].style.backgroundColor = '#B19A86'
}
let push_kyzs = (item) => {
  emits("kyzs_header", item.id)
}
let kyzs_item_ = (item) => {
  emits("kyzs_item", item)
}
const el_car = ref(null)
// 点击小圆点
let edit_xyd = (ee) => {
  el_car.value.setActiveItem(ee)
}
</script>
<style lang="less" scoped>
@maxw: 128rem;

.keyue_box {
  position: relative;
  flex: 1;
  height: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  margin-top: -12/@maxw !important;

  >header {
    -webkit-font-smoothing: subpixel-antialiased;
    width: 100%;
    height: 60/@maxw;
    margin-bottom: 15/@maxw;
    font-size: 39/@maxw;
    // font-family: Source Han Sans CN-Medium;
    // font-weight: bolder;
    font-family: fantBold;
    font-weight: 300;
    color: #174994;
    border-bottom-style: solid;
    border-bottom-width: 4/@maxw;
    border-image: linear-gradient(to right, #174994, #387DE2) 0 0 100 0;
    display: flex;
    justify-content: space-between;
    padding: 0 0 18/@maxw 0;

    >a {
      margin: 10/@maxw 0 0 0;
      font-family: Source Han Sans CN-Medium;
      font-size: 18/@maxw;
      line-height: 60/@maxw;
      color: #B19A86;
      font-weight: 800;
    }
  }

  >ul {
    position: absolute !important;
    bottom: -70/@maxw !important;
    left: 0 !important;
    z-index: 999999;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    list-style: none;
    cursor: pointer;

    >li {
      box-sizing: border-box;
      height: 13/@maxw;
      width: 13/@maxw;
      border-radius: 50%;
      // background: #B19A86;
      margin: 0 15/@maxw 0 0;
      border: 2px solid #B19A86;
    }
  }

  .el_car {
    width: 100%;
    overflow: hidden;
  }

  .role {
    margin-top: 20/@maxw;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    >section {
      display: flex;
      width: 496/@maxw;
      height: 251/@maxw;
      font-size: 20/@maxw;
      color: #fff;
      overflow: hidden;
      cursor: pointer;
      transition: all .5s;

      .leftImg {
        width: 205/@maxw;
        height: 100%;
        overflow: hidden;

        >img {
          vertical-align: top;
          width: 100%;
          height: 100%;
          transition: all .3s;
          transform: scale(1.02);
          object-fit: cover;
        }
      }

      .nav {
        position: relative;
        flex: 1;
        height: 100%;
        background: url("../assets/kyzsback3.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 0 30/@maxw;
        overflow: hidden;
        transition: all .3s;

        >p {
          padding: 0;
          margin: 57/@maxw 0 0 0;
          font-size: 24/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: bold;
          color: #FFFFFF;
        }

        >span {
          margin: 10/@maxw 0 0 0;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          font-size: 24/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 1.8;
        }

        >a {
          float: right;
          font-size: 20/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: 500;
          color: #FFFFFF;
          margin-right: -15/@maxw !important;
        }

        i {
          position: absolute;
          top: 22/@maxw;
          right: 22/@maxw;
          font-size: 100/@maxw;
          background-image: url("../assets/yinhao.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 35/@maxw;
          height: 35/@maxw;
        }
      }
    }

    section:hover {

      // box-shadow: 0 0 5px rgb(90, 90, 90);
      >.leftImg img {
        transform: scale(1.08) !important;
      }

      >.nav {
        background: url("../assets/kyzsback2.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        >span {
          color: #174994 !important;
        }

        >p {
          color: #174994 !important;
        }

        >a {
          color: #174994 !important;
        }
      }
    }

    .role_two {
      margin: 0 30/@maxw;
    }
  }

}</style>