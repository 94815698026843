<template>
  <div class="box_phone">
    <Headerphone />
    <div class="swiper">
      <!-- 分页器 -->
      <ul ref="pagination_banner">
        <li v-for="(item, i) in imgarr.list" :key="i"></li>
        <!-- 显示轮播图的数量 -->
        <div class="banner_num">
          <span>0{{ banner_num }}</span>/<span>0{{ imgarr.list.length }}</span>
        </div>
      </ul>
      <swiper class="swiper" :modules="modules" :slides-per-view="1" :speed="1500" :loop="true" :autoplay="{ delay: 4000 }"
        @slideChange="onSlideChange" @swiper="onSwiper">
        <swiper-slide v-for="(item, i) in imgarr.list" :key="i" class="swiper_slide_img">
          <div id="button_" @click="button_banner(item)">
            <span>发现更多</span>
            <img src="@/assets/fxwm.png">
          </div>
          <img :src="item.url" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <!-- 中间主体部分 -->
    <nav class="nav">
      <!-- 主体版心居中 -->
      <div class="nav_bx">
        <div class="rdjj">
          <div class="left_text">
            <h1 @click="home_classification(27)">热点聚焦 <a>MORE></a></h1>
            <nav @click="home_wz_xq_time(homeList.rdjjList2)">
              <span>{{ homeList.rdjjList2.created_at }}</span>
              <h1>{{ homeList.rdjjList2.title }}</h1>
              <p>{{ homeList.rdjjList2.description }}</p>
              <!-- 在移动手机端下 right_text在此显示 -->
              <div class="right_text right_text1" @click.stop="home_wz_xq_time(homeList.rdjjList1)">
                <a>MORE></a>
                <div class="img1"><img :src="baseUrl + homeList.rdjjList1.thumb" alt=""></div>
                <div class="img2">
                  <section></section>
                  <div class="nav_title">
                    <h1>
                      <span>{{ homeList.rdjjList1.title }}</span>
                      <p>{{ homeList.rdjjList1.description }}</p>
                    </h1>
                    <div class="right_date">
                      <span>{{ homeList.rdjjList1.created_at }}</span>
                      <em></em>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <div class="footer" @click="home_wz_xq_time(homeList.rdjjList3)">
              <span>{{ homeList.rdjjList3.created_at }}</span>
              <h1>{{ homeList.rdjjList3.title }}</h1>
            </div>
          </div>
          <!-- 在移动iPad端下 right_text在此显示 -->
          <div class="right_text right_text2" @click="home_wz_xq_time(homeList.rdjjList1)">
            <a>MORE></a>
            <div class="img1"><img :src="baseUrl + homeList.rdjjList1.thumb" alt=""></div>
            <div class="img2">
              <section></section>
              <div class="nav_title">
                <h1>
                  <span>{{ homeList.rdjjList1.title }}</span>
                  <p>{{ homeList.rdjjList1.description }}</p>
                </h1>
                <div class="right_date">
                  <span>{{ homeList.rdjjList1.created_at }}</span>
                  <em></em>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 学院新闻 -->
        <div class="xyxw">
          <Zytzxyxw :zXdata="homeList.xsxwData" @home_header='home_classification' @home_item="home_wz_xq_time" />
        </div>
        <!-- 重要通知 -->
        <div class="zytz">
          <Zytzxyxw :type="1" :zXdata="homeList.zytzData" @home_header='home_classification'
            @home_item="home_wz_xq_time" />
        </div>
        <!-- 学术讲座 -->
        <div class="xsjz">
          <ul ref="ull_xsjz">
            <li v-for="(item_, iii) in homeList.xsjzData.item" :key="iii"></li>
          </ul>
          <h1 @click="home_classification(homeList.xsjzData.id)">学术讲座 <a>MORE></a></h1>
          <swiper class="carousel" :modules="modules_" :slides-per-view="1" :speed="1500" :loop="true"
            :autoplay="{ delay: 4000 }" @slideChange="onSlideChange_two">
            <swiper-slide class="slide" v-for="(item, i) in homeList.xsjzData.item" :key="i"
              @click="home_wz_xq_time(item, 29)">
              <p>{{ item.title }}</p>
            </swiper-slide>
          </swiper>
        </div>
        <!-- 学术会议 -->
        <div class="xshy">
          <h1 @click="home_classification(homeList.xshyData.id)">学术会议<a>MORE></a></h1>
          <div class="nav">
            <div class="nav_one" @click="home_wz_xq_time(homeList.xshyData.item[0], 30)">
              <p>{{ homeList.xshyData.item[0] ? homeList.xshyData.item[0].title : '加载中...' }}</p>
              <!-- <span>{{homeList.xshyData.item[0]?homeList.xshyData.item[0].created_at.slice(0,10):'0000'}}</span> -->
            </div>
            <div class="nav_two" @click="home_wz_xq_time(homeList.xshyData.item[1], 30)">
              <p>{{ homeList.xshyData.item[1] ? homeList.xshyData.item[1].title : '加载中...' }}</p>
              <!-- <span>{{homeList.xshyData.item[1]?homeList.xshyData.item[1].created_at.slice(0,10):'0000'}}</span> -->
            </div>
          </div>
        </div>
        <!-- 科苑之声 -->
        <div class="kyzs">
          <h1 @click="home_classification(homeList.kyzsData.id)">科苑之声 <a>MORE></a></h1>
          <div class="kyzs_nav">
            <nav v-for="(item1, ii) in homeList.kyzsData.item[0]" :key="ii" @click="home_wz_xq_time(item1)">
              <div class="leftImg"><img :src="baseUrl + item1.thumb"  v-if="item1.thumb"/></div>
              <div class="rightText">
                <img src="@/assets/yinhao.png">
                <div class="text">
                  <p>{{ item1.author }}</p>
                  <p>{{ item1.title }}</p>
                </div>
                <span>{{ item1.created_at ? item1.created_at.slice(0, 10) : '000' }}</span>
              </div>
            </nav>
          </div>
        </div>
        <!-- 视频区域 -->
        <div class="video_box">
          <div class="video">
            <video x5-video-player-type="h5" :src="baseUrl + src_" controls ref="gyjg_video"></video>
          </div>
          <div class="gyjg">
            <h1>光影经管 <a @click="push_gyjg()">MORE></a></h1>
            <div class="videoList">
              <nav v-for="(item, i) in homeList.gyjgData" :key="i"><img :src="baseUrl + item.thumb" @click="gyjg_url(item)">
              </nav>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <footer>
      <Footerpho id="Footerpho" />
    </footer>
  </div>
</template>
<script setup>
import Zytzxyxw from '@/components/ipadphoneZytz.vue'
import Footerpho from '@/components/footerPhone.vue'
import Headerphone from '@/components/headerPhone.vue'
import { ref, reactive, onMounted, watch, computed, nextTick, defineProps } from 'vue'
import { useRouter, useRoute } from 'vue-router'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";
import "swiper/modules/autoplay/autoplay.min.css"
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, } from "swiper";
// swiper配置
let modules = reactive([Pagination, Scrollbar, A11y, Autoplay,])
let modules_ = reactive([Navigation, Pagination, Scrollbar, A11y, Autoplay])
import * as axios from '@/api/API.js'
import base64_ from '@/hooks/useBase64text.js'
const router = useRouter()
const baseUrl = process.env.VUE_APP_BASE_URL
let imgarr = reactive({
  list: []
})
const pagination_banner = ref(null)
const banner_num = ref(1)
const homeList = ref({
  list: [],
  // 热点聚焦数据
  rdjjList1: { created_at: 0, item: [], title: '', description: '', thumb: '' },
  rdjjList2: { created_at: 0, item: [], title: '', description: '', id: 0 },
  rdjjList3: { created_at: 0, item: [], title: '', description: '' },
  // 各个模块的数据
  xsjzData: { title: "空", item: [{ title: "加载中..." }], id: 0 },
  xshyData: { title: "空", item: [], id: 0 },
  xsxwData: { title: "空", item: [{ description: '', created_at: '0000000000', }], id: 0 },
  zytzData: { title: "空", item: [{ description: '', created_at: '0000000000', }], id: 0 },
  kyzsData: { title: "空", item: [[{ author: "加载中...", description: '', thumb: '', created_at: "0000000000" }]], id: 0 },
  // 光影经管视频数据
  gyjgData: []
})
// 轮播图切换事件
let onSlideChange = (e) => {
  // banner对应数量激活显示的数字
  // banner_num.value = e.activeIndex==5?1:e.activeIndex == 0?4:e.activeIndex
  banner_num.value = e.realIndex + 1

  const ul_ = pagination_banner.value.children;
  for (let i = 0; i < ul_.length; i++) {
    if (ul_) {
      ul_[i].style['height'] = '1px'
      ul_[i].style['margin-top'] = '0px'
    }
  }
  nextTick(() => {
    if (ul_[1]) {
      ul_[e.realIndex] ? ul_[e.realIndex].style['height'] = 5 / 10.23 + 'vw' : ''
      ul_[e.realIndex] ? ul_[e.realIndex].style['margin-top'] = -3 / 10.23 + 'vw' : ''
    }
  })
}
const props = defineProps({
  bannerData: Array,
  homeData: Object
})
// 光影经管视频地址
const src_ = ref('')
watch(() => props, () => {
  imgarr.list = props.bannerData.slice(1)
  homeList.value = props.homeData
  src_.value = homeList.value.gyjgData[0] ? homeList.value.gyjgData[0].url : ''
  nextTick(() => {
    const ul_ = pagination_banner.value.children
    if (ul_) {
      ul_[0].style['height'] = 5 / 10.23 + 'vw'
      ul_[0].style['margin-top'] = -4 / 10.23 + 'vw'
    }
  })

}, { deep: true })
const ull_xsjz = ref(null)
// 学术讲座轮播图发生变化给对应的li标签添加背景
let onSlideChange_two = (e) => {
  nextTick(() => {
    if (ull_xsjz.value.children[e.realIndex]) {
      for (let i = 0; i < ull_xsjz.value.children.length; i++) {
        ull_xsjz.value.children[i].style.backgroundColor = ''
      }
      ull_xsjz.value.children[e.realIndex].style.backgroundColor = '#B19A86'
    }
  })
}
// 点击光影经管列表事件
const gyjg_url = (ite) => {
  src_.value = ite.url
}
const gyjg_video = ref(null)
onMounted(() => {
  // 页面加载就播放光影经管视频，一秒后暂停，解决移动端不显示视频封面问题
  // gyjg_video.value.play()
  // setTimeout(() => {
  // gyjg_video.value.pause()
  // },100)

})

// 点击文章跳转到文章详情页,又发布时间页面
// 点击首页模块，跳转到对应路由的文章列表
const home_classification = (str, index) => {
  const item_route = homeList.value.list.find(item => item.id == str)
  const routerIndex = homeList.value.list.findIndex(item => item.id == str)
  router.push({
    name: item_route.vue_url,
    params: {
      obj: base64_.encode(JSON.stringify({
        type_id: item_route.id,
        title1: item_route.title,
        index: routerIndex
      }))
    }
  })
}
// 点击文章跳转到文章详情页,又发布时间页面
let home_wz_xq_time = (row) => {
  const index = row.type_id == 26 ? 27 : row.type_id
  const routerIndex = homeList.value.list.findIndex(item => item.id == index)
  router.push({
    name: "Article_xq_time",
    params: {
      obj: base64_.encode(JSON.stringify({
        title1: row.type_name,
        article_wz_id: row.id,
        type_id: 1,
        index: routerIndex
      }))
    }
  })
}
// 点击光影经管更多按钮
const push_gyjg = (item) => {
  const routerIndex = homeList.value.list.findIndex(item => item.id == 38)
  router.push({
    name: "Gyjg",
    params: {
      obj: base64_.encode(JSON.stringify({
        title1: "光影经管",
        type_id: 1,
        index: routerIndex
      }))
    }
  })
}
// 轮播图按钮
const button_banner = (eee) => {
  if (eee.link_type == 0 && eee.link) {
    window.open(eee.link)
  }
  else if (eee.link_type == 1 && eee.link) {
    if (eee.link == 169) {
      router.push({
        name: "Yzjy",
        params: {
          obj: base64_.encode(JSON.stringify({
            article_id: '55',
            id: 32,
            title1: "学院概况",
          }))
        }
      })
    } else {
      router.push({
        name: "Article_xq_time",
        params: {
          obj: base64_.encode(JSON.stringify({
            article_wz_id: eee.link,
            id: "32",
            title1: "学院概况"
          }))
        }
      })
    }
  }
}

const onSwiper = () => { }
</script>
<style lang="less" scoped>
@maxw: 1020/100vw;
// @maxw_:750/100vw;
@maxw_: 750/100vw;

.box_phone {
  box-sizing: border-box;
  width: 1023/@maxw;

  .swiper {
    position: relative;
    width: 1023/@maxw;
    height: 660/@maxw;

    >ul {
      cursor: pointer;
      box-sizing: border-box;
      position: absolute;
      bottom: 50/@maxw;
      left: 100/@maxw;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: left;
      width: 100%;
      z-index: 9999999;

      li {
        width: 107/@maxw;
        height: 1/@maxw;
        background-color: #fff;
      }

      .banner_num {
        margin: 0;
        padding: 0;
        margin: -20/@maxw 0 0 60/@maxw !important;
        font-size: 20/@maxw;
        font-family: Lora-Regular-, Lora-Regular;
        font-weight: bold;
        color: #DEDEDE;
        z-index: 999999;

        >span:nth-child(1) {
          font-size: 26/@maxw;
        }
      }
    }

    .swiper_slide_img {
      img {
        width: 100%;
        height: 100%;
        vertical-align: top;
      }

      video {
        width: 100%;
        height: 100%;
        // transform: scale(1.008);
        object-fit: cover
      }

      #button_ {
        position: absolute;
        top: 50%;
        z-index: 999;
        left: 110/@maxw;
        width: 140/@maxw;
        height: 50/@maxw;
        font-size: 18/@maxw;
        background-color: #fff;
        border-radius: 33/@maxw;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-weight: bold;
        color: #174994;

        img {
          width: 14/@maxw;
          height: 11/@maxw;
        }
      }
    }
  }

  .nav {
    width: 100%;
    background-color: #fff;
    padding-top: 100/@maxw;

    .nav_bx {
      margin: 0 auto;
      width: 922/@maxw;

      .rdjj {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .left_text {
          min-width: 425/@maxw;
          // height: 490/@maxw;
          flex: 1;
          border-bottom: 4/@maxw solid #aaa;
          margin-bottom: 30/@maxw;

          >h1 {
            margin: 0;
            padding: 0 0 50/@maxw 0 !important;
            width: 100%;
            height: 60/@maxw;
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
            border-bottom-style: solid;
            border-bottom-width: 4/@maxw;
            border-image: linear-gradient(to right, #174994 0%, #387DE2 100%) 4;
            font-size: 42/@maxw;
            font-family: fantBold;
            font-weight: 300;
            color: #174994;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            justify-content: space-between;
            transition: ease .3s;

            >a {
              display: none;
            }
          }

          nav {
            width: 100%;
            height: 295/@maxw;
            border-bottom: 4/@maxw solid #aaa;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: #000;
            padding: 0 0 10/@maxw 0;
            transition: ease .3s;

            >span {
              font-size: 13/@maxw;
              margin-top: 20/@maxw;
            }

            >h1 {
              margin: 10/@maxw 0;
              font-size: 28/@maxw;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              // overflow: hidden;
              line-height: 1.3;
              transition: ease .3s;
            }

            >P {
              margin: 0 0 30/@maxw 0;
              font-size: 16/@maxw;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5;
              overflow: hidden;
              line-height: 2;
              transition: ease .3s;
              color: #292929;
              // font-style: Source Han Sans CN-Medium;
            }
          }

          .footer {
            width: 100%;
            transition: ease .3s;
            color: #000;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            >span {
              font-size: 13/@maxw;
              margin-top: 20/@maxw;
            }

            >h1 {
              font-size: 28/@maxw;
              margin: 0;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
        }

        .right_text1 {
          display: none;
        }

        .right_text {
          box-sizing: border-box;
          flex: 1;
          min-width: 461/@maxw;
          margin: -5/@maxw 0 0 30/@maxw;
          height: 545/@maxw;
          cursor: pointer;
          transition: ease .5s;
          overflow: hidden;

          >a {
            float: right;
            font-size: 26/@maxw;
            font-family: Source Han Sans CN-Medium;
            font-weight: 500;
            color: #B19A86;
            line-height: 60/@maxw;
            transition: ease .3s;
          }

          >.img1 {
            width: 100%;
            height: 295/@maxw;
            overflow: hidden;

            >img {
              width: 100%;
              height: 100%;
              vertical-align: top;
              transition: all .5s;
            }
          }

          >.img2 {
            padding: 0 !important;
            margin: 0 !important;
            position: relative;
            width: 100%;
            height: 145/@maxw;
            background-color: #ccc;
            z-index: 9999;

            >section {
              position: absolute;
              top: 0;
              right: 0;
              width: 100%;
              height: 100%;
              background-image: url("../assets/yddRightback.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              transition: ease .3s;
              z-index: -999;
            }

            .nav_title {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: space-between;
              overflow: hidden;
              transition: ease .3s;

              >h1 {
                transition: ease .3s;
                padding: 33/@maxw 0 0 23/@maxw;
                margin: 0;
                width: 70%;
                height: 100%;
                font-size: 24/@maxw;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: bold;
                color: #174994;

                >span {
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  overflow: hidden !important;
                }

                p {
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                  margin: 10/@maxw 0 0 0;
                }
              }

              >.right_date {
                width: 30%;
                height: 100%;
                font-size: 13/@maxw;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                padding-top: 28/@maxw;
                display: flex;
                justify-content: space-between;
                transition: ease .3s;

                span {
                  margin-left: -20/@maxw;
                }

                >em {
                  margin-right: 30/@maxw;
                  width: 24/@maxw;
                  height: 25/@maxw;
                  font-style: normal;
                  background-image: url("../assets/icon.png");
                  background-size: 100% 100% !important;
                  background-repeat: no-repeat;
                }
              }
            }
          }
        }
      }

      .xsjz {
        position: relative;
        margin-top: 60/@maxw;

        >ul {
          position: absolute;
          bottom: 30/@maxw;
          z-index: 9999;
          display: flex;
          justify-content: center;
          width: 100%;
          margin: 0;
          padding: 0;

          li {
            margin: 0 10/@maxw;
            width: 18/@maxw;
            height: 18/@maxw;
            border-radius: 50%;
            border: 2px solid #B19A86;
          }
        }

        >h1 {
          margin: 0;
          padding: 0 0 30/@maxw 0 !important;
          width: 100%;
          height: 60/@maxw;
          font-size: 42/@maxw;
          font-family: fantBold;
          font-weight: 300;
          color: #174994;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: space-between;
          transition: ease .3s;
          transform-style: preserve-3d;

          >a {
            float: right;
            font-size: 26/@maxw;
            font-family: Source Han Sans CN-Medium;
            font-weight: 500;
            color: #B19A86;
            line-height: 60/@maxw;
            transition: ease .3s;
          }
        }

        .carousel {
          width: 100% !important;
          height: 258/@maxw !important;
          background-image: url("../assets/ipadJzback.jpg");
          background-size: 100% 100%;

          .slide {
            >p {
              width: 851/@maxw;
              font-size: 28/@maxw;
              font-family: Source Han Sans CN-Medium;
              font-weight: 500;
              color: #FFFFFF;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
          }
        }
      }

      .xshy {
        margin-top: 50/@maxw;

        h1 {
          margin: 0;
          padding: 0 0 30/@maxw 0 !important;
          width: 100%;
          height: 60/@maxw;
          font-size: 42/@maxw;
          font-family: fantBold;
          font-weight: 300;
          color: #174994;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: space-between;
          transition: ease .3s;

          >a {
            float: right;
            font-size: 26/@maxw;
            font-family: Source Han Sans CN-Medium;
            font-weight: 500;
            color: #B19A86;
            line-height: 60/@maxw;
            transition: ease .3s;
          }
        }

        .nav {
          width: 100%;
          height: 312/@maxw;
          background-image: url("../assets/huiyibj.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 35/@maxw;

          .nav_one,
          .nav_two {
            position: relative;
            height: 50%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >p {
              margin: 0;
              padding: 0;
              font-size: 28/@maxw;
              font-family: Source Han Sans CN-Medium;
              font-weight: 500;
              color: #174994;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }

            >span {
              position: absolute;
              bottom: 30/@maxw;
              right: 15/@maxw;
              font-size: 20/@maxw;
              font-family: Source Han Sans CN-Medium;
              font-weight: 500;
              color: #517DBE;
            }
          }

          .nav_two::before {
            background-color: red;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-color: #fff;
            width: 100%;
            height: 1px;
          }
        }
      }

      .kyzs {
        position: relative;
        z-index: 99999;
        margin-top: 50/@maxw;

        >h1 {
          margin: 0;
          padding: 0 0 30/@maxw 0;
          width: 100%;
          height: 60/@maxw;
          border-bottom-style: solid;
          border-bottom-width: 5/@maxw;
          border-image: linear-gradient(90deg, #174994 0%, #387DE2 100%) 4;
          font-size: 42/@maxw;
          font-family: fantBold;
          font-weight: 300;
          color: #174994;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: space-between;
          transition: ease .3s;

          >a {
            float: right;
            font-size: 26/@maxw;
            font-family: Source Han Sans CN-Medium;
            font-weight: 500;
            color: #B19A86;
            line-height: 60/@maxw;
            transition: ease .3s;
          }
        }

        .kyzs_nav {
          position: relative;
          z-index: 99999;
          width: 100%;
          height: 1070/@maxw;

          nav {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 341/@maxw;
            overflow: hidden;

            .leftImg {
              overflow: hidden;
              width: 320/@maxw;
              height: 100%;

              img {
                width: 100%;
                height: 100%;
                vertical-align: top;
                object-fit: cover;
              }
            }

            .rightText {
              position: relative;
              flex: 1;
              height: 100%;
              background-image: url("../assets/kyzsIpad.png");
              background-size: 100% 100%;

              >img {
                position: absolute;
                top: 30/@maxw;
                right: 40/@maxw;
                width: 45/@maxw;
                height: 45/@maxw;
              }

              .text {
                margin: 80/@maxw 0 0 60/@maxw;
                width: 480/@maxw;
                height: 170/@maxw;
                font-size: 30/@maxw;
                font-family: Source Han Sans CN-Medium;
                font-weight: 500;
                color: #FFFFFF;

                p {
                  margin: 6/@maxw 0 0 0;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                }
              }

              >span {
                float: right;
                font-size: 26/@maxw;
                font-family: Source Han Sans CN-Medium;
                font-weight: 500;
                color: #FFFFFF;
                margin-right: 50/@maxw;
              }
            }
          }

          >nav:nth-child(1) {
            margin: 25/@maxw 0 0 0;
          }

          >nav:nth-child(2) {
            margin: 25/@maxw 0;
          }

        }
      }

      .kyzs::after {
        position: absolute;
        bottom: -100/@maxw;
        left: -50/@maxw;
        content: "";
        width: 1023/@maxw;
        height: 50%;
        background-image: url("../assets/fff.jpg");
        background-size: 100% 100%;
        z-index: -999999;
      }

      .video_box {
        width: 1023/@maxw;
        margin: 100/@maxw 0 0 -52/@maxw;

        >.video {
          overflow: hidden;
          box-sizing: border-box;
          width: 100%;
          height: 513/@maxw;

          >video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .gyjg {
          width: 1023/@maxw;
          height: 383/@maxw;
          background-image: url("../assets/gyjgIpad.png");
          background-size: 100% 100%;

          >h1 {
            margin: 0 0 0 50/@maxw;
            padding: 110/@maxw 0 30/@maxw 0 !important;
            width: 100%;
            height: 60/@maxw;
            font-size: 42/@maxw;
            font-family: fantBold;
            font-weight: 300;
            color: #fff;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            justify-content: space-between;
            transition: ease .3s;

            >a {
              float: right;
              font-size: 26/@maxw;
              font-family: Source Han Sans CN-Medium;
              font-weight: 500;
              color: #fff;
              line-height: 60/@maxw;
              transition: ease .3s;
              margin-right: 80/@maxw;
            }
          }

          .videoList {
            display: flex;
            justify-content: center;
            margin-top: 31/@maxw;

            nav {
              position: relative;
              width: 279/@maxw;
              height: 170/@maxw;
              background-color: #B19A86;

              img {
                width: 100%;
                height: 100%;
                vertical-align: top;
              }
            }

            >nav:nth-child(2) {
              margin: 0 42/@maxw;
            }

            >nav::after {
              content: "";
              position: absolute;
              top: 35%;
              left: 43%;
              width: 40/@maxw;
              height: 40/@maxw;
              background-image: url("../assets/bofang.png");
              background-size: 100% 100%;
              transform: rotate(-90deg) !important;
            }
          }
        }
      }
    }
  }
}

.Footerpho {
  margin-top: 100vh !important;
}

@media screen and (max-width:750px) {
  .headers {
    .left_logo {
      width: 435/@maxw_ !important;
      height: 65/@maxw_ !important;
    }
  }

  .swiper {
    margin-top: 47/@maxw_;
    width: 100% !important;
    height: 440/@maxw_ !important;

    ul {
      margin: 20/@maxw_ 0 0 20/@maxw_ !important;
    }

    .swiper_slide_img {
      overflow: hidden !important;

      >img {
        // width: 1060/@maxw_ !important;
        // height: 638/@maxw_ !important;
        width: 750/@maxw_ !important;
        height: 440/@maxw_ !important;
      }

      #button_ {
        width: 125/@maxw_ !important;
        height: 50/@maxw_ !important;
        font-size: 16/@maxw_ !important;
        // top:65% !important;
        top: 55% !important;
        // left: 100/@maxw_ !important;
      }
    }
  }

  .right_text1 {
    display: block !important;
  }

  .nav {
    padding: 25/@maxw_ 0 0 0 !important;
  }

  .rdjj {
    margin-top: 48/@maxw_ !important;
    display: flex !important;
    flex-direction: column !important;

    .left_text {
      >h1 {
        padding-bottom: 16/@maxw_ !important;
        font-size: 42/@maxw_ !important;
        border-bottom-style: solid;
        border-bottom-width: 5/@maxw_;
        border-image: linear-gradient(to right, #174994 0%, #387DE2 100%) 4;

        >a {
          display: block !important;
          float: right;
          font-size: 26/@maxw_ !important;
          font-family: Source Han Sans CN-Medium !important;
          font-weight: 500;
          color: #B19A86;
          line-height: 60/@maxw_;
          transition: ease .3s;
        }
      }

      nav {
        height: auto !important;
        border-bottom: none !important;

        >span {
          margin: 32/@maxw_ 0 !important;
          font-size: 24/@maxw_ !important;
          font-family: Source Han Sans CN-Medium !important;

        }

        >h1 {
          font-size: 34/@maxw_ !important;
          color: #292929 !important;
          line-height: 1.3 !important;
          padding: 0 !important;
          margin: 0 0 24/@maxw_ 0 !important;
        }

        >p {
          font-size: 22/@maxw_ !important;
          -webkit-line-clamp: 3 !important;
          line-height: 36/@maxw_ !important;
          font-family: Source Han Sans CN-Medium;
          // font-weight: 300;
        }
      }

      .footer {
        display: none !important;

        >span {
          font-size: 24/@maxw_ !important;
        }

        >h1 {
          font-size: 34/@maxw_ !important;
          margin: 20/@maxw_ 0 !important;
        }
      }
    }

    .right_text2 {
      display: none !important;
    }

    .right_text {
      margin: 15/@maxw_ 0 !important;
      flex: 1;
      min-width: 461/@maxw_ !important;
      height: 526/@maxw_ !important;
      padding-bottom: 15/@maxw_;

      a {
        display: none !important;
      }

      .img1 {
        height: 392/@maxw_ !important;
      }

      .img2 {
        height: 135/@maxw_ !important;

        >section {
          background-image: url("../assets/phoneRightback.png") !important;
        }

        .nav_title {
          >h1 {
            font-size: 30/@maxw_ !important;
            padding: 33/@maxw 0 0 50/@maxw !important;
            line-height: 40/@maxw_ !important;
          }

          >.right_date {
            span {
              font-size: 20/@maxw_ !important;
              margin-left: 8/@maxw_ !important;
              margin-top: 10/@maxw_;
              font-family: Source Han Sans CN-Medium;
            }

            em {
              margin-top: 10/@maxw_;
              width: 30/@maxw_ !important;
              height: 30/@maxw_ !important;
            }
          }
        }
      }
    }
  }

  .xsjz {
    >h1 {
      font-size: 42/@maxw_ !important;

      // margin-bottom: 23/@maxw !important;
      >a {
        font-size: 26/@maxw_ !important;
      }
    }

    .swiper {
      margin: 15/@maxw_ 0 0 0 !important;
    }

    .slide {
      position: relative !important;

      >p {
        font-size: 25/@maxw_ !important;
        margin-left: 20/@maxw_;
        // height: 58%;
        height: 100/@maxw_;

        >span {
          position: absolute;
          bottom: 40/@maxw_;
          right: 40/@maxw_;
          font-size: 22/@maxw_;
        }
      }
    }
  }

  .xshy {
    margin: 50/@maxw_ 0 !important;

    >h1 {
      margin-top: 50/@maxw_ !important;
      font-size: 42/@maxw_ !important;

      >a {
        font-size: 26/@maxw_ !important;
      }
    }

    .nav {
      height: 300/@maxw_ !important;
      padding: 23/@maxw_ !important;
      margin-top: 23/@maxw_ !important;

      >div {
        margin: 0 !important;
        padding: 0 !important;

        >p {
          font-size: 26/@maxw_ !important;
          line-height: 36/@maxw_ !important;
        }

        >span {
          font-size: 18/@maxw_ !important;
          margin-right: 10/@maxw_;
        }
      }
    }
  }

  .kyzs {
    margin-top: 53/@maxw_ !important;
    margin-bottom: 65/@maxw_ !important;

    >h1 {
      font-size: 42/@maxw_ !important;
      padding-bottom: 40/@maxw_ !important;

      >a {
        font-size: 26/@maxw_ !important;
      }
    }

    nav {
      margin-top: 30/@maxw_ !important;

      .text {
        font-size: 26/@maxw_ !important;
        line-height: 44/@maxw_ !important;
      }

      span {
        font-size: 20/@maxw_ !important;
      }
    }
  }

  .video_box {

    // margin-top: 65/@maxw_ !important;
    >.video {
      height: 422/@maxw_ !important;

      >video {
        object-fit: cover !important;
        height: 422/@maxw_ !important;
      }
    }

    .gyjg {
      // margin-top: 43/@maxw_ !important;
      height: 322/@maxw_ !important;

      >h1 {
        font-size: 42/@maxw_ !important;

        a {
          font-size: 26/@maxw_ !important;
        }
      }

      .videoList {
        margin-top: 45/@maxw_ !important;
      }
    }
  }
}
</style>  



