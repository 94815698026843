<template>
  <!-- 页面大盒子居中 -->
  <div class="box">
    <!-- 头部 -->
    <keep-alive>
      <Header style="position: absolute;z-index: 9999" type="1" />
    </keep-alive>
    <!-- 轮播图 区域-->
    <div class="swiper_box">
      <!-- 分页器 -->
      <ul ref="pagination_banner">
        <li v-for="(item, i) in imgarr.list" :key="i"></li>
        <!-- 显示轮播图的数量 -->
        <div class="banner_num">
          <span>0{{ banner_num }}</span>/<span>0{{ imgarr.list.length }}</span>
        </div>
      </ul>
      <swiper class="swiper" :modules="modules" :slides-per-view="1" :speed="1500" :loop="true"
        :autoplay="{ delay: 4000 }" navigation @slideChange="onSlideChange" @swiper="onSwiper">
        <swiper-slide v-for="(item, i) in imgarr.list" :key="i" class="swiper_slide_img">
          <!-- 自定义编辑banner标题 -->
          <div class="bannerTitle" v-if="false">{{ item.title }}</div>
          <div id="button_" @click="button_banner(item)" v-if="item.is_more == 1">
            <span>发现更多</span>
            <img src="@/assets/fxwm.png" />
          </div>
          <img :src="item.url" alt="" style="width:100%;height:100%" v-if="i !== 0">
          <video muted preload="none" :onended="onended" :src="item.url" v-else @canplay='canplay'
            ref="autoVideo"></video>
        </swiper-slide>
      </swiper>
      <!-- banner下面导航模块 -->
      <div class="banner_data" ref="banner_ms">
        <span v-for="(item1, i) in bannerData.list" :key="i" @click="banner_qiehuan(item1, i)">{{ item1.title }}</span>
      </div>
    </div>
    <!-- fff为主体区域的背景 白色 -->
    <div class="fff">
      <!-- loding -->
      <div class="loding" v-if="loding">
        <el-skeleton style="--el-skeleton-circle-size: 100px;display: flex;flex-direction: column;" animated>
          <template #template>
            <nav class="el-skeleton">
              <el-skeleton-item variant="h1" style="height:500px;flex:1;" />
              <el-skeleton-item variant="h1" style="height:500px;flex:1;margin:0 50px" />
              <el-skeleton-item variant="h1" style="height:500px;flex:1;" />
            </nav>
            <nav class="el-skeleton">
              <el-skeleton-item variant="h1" style="height:500px;flex:1;margin-right:10px" />
              <el-skeleton-item variant="h1" style="height:500px;flex:1;margin-left:10px" />
            </nav>
            <nav class="el-skeleton">
              <el-skeleton-item variant="h1" style="height:500px;width:600px" />
              <el-skeleton-item variant="h1" style="height:500px;width:600px" />
              <el-skeleton-item variant="h1" style="height:500px;width:600px" />
            </nav>
            <el-skeleton-item variant="h1" style="margin:100px 0" />
            <el-skeleton-item variant="h1" />
            <el-skeleton-item variant="h1" />
          </template>
        </el-skeleton>
      </div>
      <!-- 主体区域 版心区域-->
      <Transition>
        <div class="box_nav" v-show="navShow">
          <!-- 主体上部 -->
          <div class="zhuti_top">
            <div class="nav_top">
              <header @click="home_classification(27)">热点聚焦 <a>MORE></a></header>
              <nav @click="home_wz_xq_time(homeList.rdjjList2)">
                <span>{{ homeList.rdjjList2.created_at }}</span>
                <h1>{{ homeList.rdjjList2.title }}</h1>
                <p>{{ homeList.rdjjList2.description }}</p>
              </nav>
              <footer @click="home_wz_xq_time(homeList.rdjjList3)">
                <span>{{ homeList.rdjjList3.created_at }}</span>
                <h1>{{ homeList.rdjjList3.title }}</h1>
              </footer>
            </div>
            <div class="nav_middle" @click="home_wz_xq_time(homeList.rdjjList1)">
              <div class="img1"><img :src="baseUrl + homeList.rdjjList1.thumb" alt=""></div>
              <div class="img2">
                <section></section>
                <div class="nav_title">
                  <h1>
                    <!-- <span>{{homeList.rdjjList1.title}}</span> -->
                    <span>{{ homeList.rdjjList1.title.slice(0, 20) }}</span>
                    <p>{{ homeList.rdjjList1.title.slice(20) }}</p>
                  </h1>
                  <div class="right_date">
                    <span>{{ homeList.rdjjList1.created_at }}</span>
                    <em></em>
                  </div>
                </div>
              </div>
            </div>
            <div class="nav_bottom">
              <div class="nav_bottom_one">
                <ol ref="Pagerdot">
                  <li v-for="(item, i) in homeList.xsjzData.item" :key="i" @click="Jumprotation(i)"></li>
                </ol>
                <header @click="home_classification(homeList.xsjzData.id)">学术讲座 <a>MORE></a></header>
                <nav>
                  <el-carousel class="carousel_" indicator-position="none" @change="carouselChange" ref="carousel" loop>
                    <el-carousel-item v-for="(elm, i) in homeList.xsjzData.item" :key="i"
                      @click="home_wz_xq_time(elm, 29)">
                      <p class="text_p">{{ elm.title }}</p>
                    </el-carousel-item>
                  </el-carousel>
                </nav>
              </div>
              <div class="nav_bottom_tow">
                <header @click="home_classification(homeList.xshyData.id)">学术会议 <a>MORE></a></header>
                <nav>
                  <section @click="home_wz_xq_time(homeList.xshyData.item[0], 30)">
                    <p>{{ homeList.xshyData.item[0] ? homeList.xshyData.item[0].title : '空' }}</p>
                    <!-- <span>{{homeList.xshyData.item[0]?homeList.xshyData.item[0].created_at.slice(0,10):'0'}}</span> -->
                  </section>
                  <section @click="home_wz_xq_time(homeList.xshyData.item[1], 30)">
                    <p>{{ homeList.xshyData.item[1] ? homeList.xshyData.item[1].title : '空' }}</p>
                    <!-- <span>{{homeList.xshyData.item[1]?homeList.xshyData.item[1].created_at.slice(0,10):'0'}}</span> -->
                  </section>
                </nav>
              </div>
            </div>
          </div>
          <!-- 主体中部 -->
          <div class="zhuti_middle">
            <Schooljournalism class="margin" title="学院新闻" :data="homeList.xsxwData" @home_header='home_classification'
              @home_item="home_wz_xq_time">
            </Schooljournalism>
            <Schooljournalism title="重要通知" type_="1" :data="homeList.zytzData" @home_header='home_classification'
              @home_item="home_wz_xq_time">
            </Schooljournalism>
          </div>
          <!-- 主体下部 -->
          <div class="zhuti_bottom">
            <!-- 科苑之声背景 -->
            <img class="img" src="../assets/fff.jpg" alt="">
            <Keyuan class="keyuan" :data_="homeList.kyzsData" @kyzs_header="home_classification"
              @kyzs_item="home_wz_xq_time" />
          </div>
        </div>
      </Transition>
      <!-- 视频区域 -->
      <div class="video_box">
        <div class="video_top" @click="playVideo">
          <img id="backgImg" :src="baseUrl + 'uploads/images/562e533a21c7c12fa010634849f739d6.jpg'">
          <div ref="video_play_img">
            <img class="video_play_img" src="../assets/bofang.png">
            <p>点击发现更多光芒</p>
          </div>
          <video :src="baseUrl + video_src_" class="video_img" controls ref="video_" @pause="pause" @play="canplay_"
            @click="clickVideo"></video>
        </div>
        <div class="video_foot">
          <h1>光影经管</h1>
          <div class="right_video">
            <nav @click="on_imgsrc(item)" v-for="(item, i) in homeList.gyjgData" :key="i">
              <div>
                <img class="img_ejgy" :src="baseUrl + item.thumb" alt="">
                <!-- 播放按钮图片 -->
                <img class="img_ejgyplay" src="../assets/erjigybf.png" alt="">
              </div>
              <p>{{ item.title }}</p>
            </nav>

            <span @click="push_gyjg()">MOER></span>
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <Footer class="foot" />
    </div>
    <!-- 重要通知弹框 -->
    <el-dialog draggable v-model="dialogVisible_zytz" :width="1000 / 128 + 'rem'" :before-close="handleClose" top="3%"
      v-if="show_dialog">
      <div class="zytz_dialog">
        <h1>{{ homeList.dialog_content.title }}</h1>
        <nav>
          <p v-if="homeList.dialog_content.description">{{ homeList.dialog_content.description }}</p>
          <p v-html="homeList.dialog_content.content"></p>
        </nav>
      </div>
    </el-dialog>
  </div>
  <!-- iPad + 移动端 -->
  <Ipadphone class="box_phone" :homeData="homeList" :bannerData="imgarr.list" v-show="show_phone" />
</template>

<script setup>
import Header from '../components/header.vue'
import Footer from '../components/footer.vue'
import Schooljournalism from '../components/school_Journalism.vue'
import Keyuan from '../components/keyuan.vue'
import Ipadphone from '../components/Ipadphone.vue'
import { ref, reactive, onMounted, nextTick, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import * as axios from '@/api/API.js'
import base64_ from '@/hooks/useBase64text.js'
let banner_ms = ref(null)
let onSwipers = () => { }
// swiper配置
let modules = reactive([Navigation, Pagination, Scrollbar, A11y, Autoplay,])
let show_phone = ref(false)
document.documentElement.scrollTop = 0
// 判断窗口大于750就不创建移动端结构函数，以免路由数据多次渲染
const getphoneWidht = () => {
  let widthWind = window.innerWidth
  sizeWidth()
  window.addEventListener("resize", () => {
    widthWind = window.innerWidth
    sizeWidth()
  })
  function sizeWidth() {
    if (widthWind < 1020) {
      show_phone.value = true
    } else {
      show_phone.value = false
    }
  }
}
getphoneWidht()

const baseUrl = process.env.VUE_APP_BASE_URL
let imgarr = reactive({
  list: []
})
// 获取轮播图
const bannerList = async () => {
  const { data: res } = await axios.getHttp("/api/banner", '')
  if (res.code == 200) {
    imgarr.list = res.data
    // 获取轮播图数据完毕后，把页面分页器dome第一个高亮
    nextTick(() => {
      const ul_ = pagination_banner.value.children;
      getBanner_data()
      if (ul_) {
        ul_[0].style['height'] = '5px'
        ul_[0].style['margin-top'] = '-4px'
      }
    })
  }
}
let bannerData = reactive({
  list: []
})
// 获取首页banner下面的导航栏数据
const getBanner_data = async () => {
  getHomtlist()
  // 如果页面小于1020就不执行接口
  if (window.innerWidth <= 1020) return
  const { data: res } = await axios.getNavlist({ is_banner_nav: 2 })
  if (res.code == 200) {
    bannerData.list = res.data
  }
}

const pagination_banner = ref(null)
const banner_num = ref(1)
// 轮播图切换事件
let onSlideChange = (e) => {
  const imgLength = imgarr.list.length
  // banner对应数量激活显示的数字
  // banner_num.value = e.activeIndex==5?1:e.activeIndex == 0?4:e.activeIndex
  banner_num.value = e.activeIndex == imgLength + 1 ? 1 : e.activeIndex == 0 ? imgLength : e.activeIndex
  const ul_ = pagination_banner.value.children;
  for (let i = 0; i < ul_.length; i++) {
    if (ul_) {
      ul_[i].style['height'] = '1px'
      ul_[i].style['margin-top'] = '0px'
    }
  }
  nextTick(() => {
    if (ul_[1]) {
      ul_[e.realIndex].style['height'] = '5px'
      ul_[e.realIndex].style['margin-top'] = '-4px'
    }
  })
}
// 获取swiper实例
let onSwiper = (eee) => {
  onSwipers = eee
}
// 对应模块点击事件
let banner_qiehuan = (item, i) => {
  if (item.external_url) {
    router.push({
      path: item.external_url
    })
    return
  }
  //如果template_type就默认跳转子菜单第一个
  if (item.template_type == 1) {
    router.push({
      name: item.vue_url,
      params: {
        obj: base64_.encode(JSON.stringify(
          {
            title1: item.title,
            title2: item.children[0].title,
            type_id: item.children[0].id,
            id: item.children[0].pid,
            index: 0,
            article_id: item.children[0].id,
            isBanner_nav_id: item.is_banner_nav,
          }
        ))
      }
    })
    return
  }
  router.push({
    name: item.vue_url,
    params: {
      obj: base64_.encode(JSON.stringify({
        title1: item.title,
        index: -1,
        article_id: item.id,
        id: item.id,
        isBanner_nav_id: item.id,
        type_id: item.id,
      }))
    }
  })
}
const dialogVisible_zytz = ref(false)
// 当浏览器可以开始播放音视频时弹框重要通知。
let canplay = () => {
  const flag = window.sessionStorage.getItem("flag")
  // 在app根组件中存储flag为1，判断如果本地存的是1就弹框，否则不弹框
  if (flag == 1) {
    window.sessionStorage.setItem("flag", 2)
    dialogVisible_zytz.value = true
  }
}
// 导入动画函数
import animation_ from '@/utils/animation.js'
onMounted(_ => {
  onSwipers.autoplay.stop()
  onSwipers.slideTo(1)
  // 获取轮播图数据
  bannerList()
  // 使用动画函数
  nextTick(_ => {
    animation_(document.querySelector(".zhuti_top"))
    animation_(document.querySelector(".zhuti_middle"))
    animation_(document.querySelector(".zhuti_bottom"))
    animation_(document.querySelector(".right_video"))
  })

})

// 当首页banner区域视频播放完毕执行事件
const onended = () => {
  onSwipers.autoplay.start()
}
let loding = ref(true)
let navShow = ref(false)
// 获取首页列表数据
const homeList = reactive({
  dialog_content: {},
  list: [],
  // 热点聚焦数据
  rdjjList1: { created_at: 0, item: [], title: '', description: '', thumb: '' },
  rdjjList2: { created_at: 0, item: [], title: '', description: '', id: 0, thumb: '' },
  rdjjList3: { created_at: 0, item: [], title: '', description: '', thumb: '' },
  // =======
  //   rdjjList2: { created_at: 0, item: [], title: '', description: '', id: 0 },
  //   rdjjList3: { created_at: 0, item: [], title: '', description: '' },
  // >>>>>>> e2b98bee83e40b0e0d988bc8e604a4fcda1700df
  // 各个模块的数据
  xsjzData: { title: "空", item: [{ title: "加载中..." }], id: 0 },
  xshyData: { title: "空", item: [], id: 0 },
  xsxwData: { title: "空", item: [{ description: '', created_at: '0000000000', }], id: 0 },
  zytzData: { title: "空", item: [{ description: '', created_at: '0000000000', }], id: 0 },
  kyzsData: { title: "空", item: [{ author: "加载中...", description: '', thumb: '' }], id: 0 },
  // 光影经管视频数据
  gyjgData: []
})

const getHomtlist = async () => {
  const { data: res } = await axios.get_home_list()
  if (res.code == 200) {
    getRdjjlist()
    homeList.list = res.data
    homeList.xsjzData = res.data.find(item => item.id == 29)
    homeList.xshyData = res.data.find(item => item.id == 30)
    homeList.xsxwData = res.data.find(item => item.id == 27)
    homeList.zytzData = res.data.find(item => item.id == 28)
    homeList.kyzsData = res.data.find(item => item.id == 31)
    // 确定数据加载完毕并且dom更新后，学术讲座轮播默认为1的索引设置背景
    nextTick(_ => {
      if (Pagerdot.value.children[0]) {
        Pagerdot.value.children[0].style.backgroundColor = '#B19A86'
      }
      loding.value = false
      navShow.value = true
    })
  }
}

const router = useRouter()
// 点击首页模块，跳转到对应路由的文章列表
const home_classification = (str, index) => {
  const item_route = homeList.list.find(item => item.id == str)
  const routerIndex = homeList.list.findIndex(item => item.id == str)
  router.push({
    name: item_route.vue_url,
    params: {
      obj: base64_.encode(JSON.stringify({
        type_id: item_route.id,
        title1: item_route.title,
        index: routerIndex
      }))
    }
  })
}

const home_header = (ee) => {
  home_classification(ee)
}
const Pagerdot = ref(null)
const carousel = ref(null)
// 学术讲座轮播发生改变
const carouselChange = (e) => {
  const li = Pagerdot.value.children
  for (let i = 0; i < li.length; i++) {
    li[i].style.backgroundColor = ''
  }
  li[e].style.backgroundColor = '#B19A86'
}
// 点击小圆点跳转对应位置
const Jumprotation = (ee) => {
  carousel.value.setActiveItem(ee)
}

// 获取热点聚焦数据
const getRdjjlist = async () => {
  const { data: res } = await axios.getHttp("/api/rdjjList")
  if (res.code == 200) {
    homeList.rdjjList1 = res.data[0][0]
    homeList.rdjjList1.created_at = res.data[0][0].created_at.slice(0, 10)
    homeList.rdjjList2 = res.data[0][1]
    homeList.rdjjList2.created_at = res.data[0][1].created_at.slice(0, 10)
    homeList.rdjjList3 = res.data[0][2]
    homeList.rdjjList3.created_at = res.data[0][2].created_at.slice(0, 10)
    getGyjglist()
  }
}

// 轮播图按钮
const button_banner = (eee) => {
  if (eee.link_type == 0 && eee.link) {
    window.open(eee.link)
  }
  else if (eee.link_type == 1 && eee.link) {
    if (eee.link == 169) {
      router.push({
        name: "Yzjy",
        params: {
          obj: base64_.encode(JSON.stringify({
            article_id: '55',
            id: 32,
            title1: "学院概况",
          }))
        }
      })
    } else {
      router.push({
        name: "Article_xq_time",
        params: {
          obj: base64_.encode(JSON.stringify({
            article_wz_id: eee.link,
            id: "32",
            title1: "学院概况"
          }))
        }
      })
    }
  }
}
const video_thumb_ = ref('')
// 获取光影经管视频列表
const getGyjglist = async () => {
  const { data: res } = await axios.getHttp('/api/video')
  if (res.code == 200) {
    getSetup()
    homeList.gyjgData = res.data.slice(0, 3)
    video_src_.value = res.data[0].url
  }
}

// 获取设置数据（是否弹框）
const show_dialog = ref(false)
let getSetup = async () => {
  const { data: res } = await axios.getSetup()
  res.data.value == 0 ? show_dialog.value = false : show_dialog.value = true
  homeList.dialog_content = res.data
}

let video_play_img = ref(null)
let video_ = ref(null)
const video_src_ = ref('')
// 点击视频列表 播放视频并且赋值视频封面
let on_imgsrc = (row) => {
  video_.value.style.opacity = 0
  video_play_img.value.style.display = 'block'
  document.querySelector("#backgImg").style.opacity = '0'
  // video_.5秒后动态赋值样式动画
  setTimeout(() => {
    video_.value.style.opacity = 1
    video_src_.value = row.url
    document.querySelector("#backgImg").style.opacity = '1'
  }, 500)
}
// 点击播放按钮播放视频
const playVideo = () => { video_.value.play() }
// 视频暂停触发事件
let pause = () => {
  video_play_img.value.style.display = 'block'
  // document.querySelector("#backgImg").style.display = 'block'
}
// 点击文章跳转到文章详情页
let home_wz_xq = (row) => {
  const index = row.type_id == 26 ? 27 : row.type_id
  const routerIndex = homeList.list.findIndex(item => item.id == index)
  router.push({
    name: "Article_xq",
    params: {
      obj: base64_.encode(JSON.stringify({
        title1: row.type_name,
        article_wz_id: row.id,
        type_id: 1,
        index: routerIndex
      }))
    }
  })
}
// 点击文章跳转到文章详情页,有发布时间页面
let home_wz_xq_time = (row) => {
  const index = row.type_id == 26 ? 27 : row.type_id
  const routerIndex = homeList.list.findIndex(item => item.id == index)
  router.push({
    name: "Article_xq_time",
    params: {
      obj: base64_.encode(JSON.stringify({
        title1: row.type_name,
        article_wz_id: row.id,
        type_id: 1,
        index: routerIndex
      }))
    }
  })
}
// 点击光影经管更多按钮
const push_gyjg = (item) => {
  const routerIndex = homeList.list.findIndex(item => item.id == 38)
  router.push({
    name: "Gyjg",
    params: {
      obj: base64_.encode(JSON.stringify({
        title1: "光影经管",
        type_id: 1,
        index: routerIndex
      }))
    }
  })
}
// 监听video标签播放事件
let canplay_ = () => {
  video_play_img.value.style.display = 'none'
  document.querySelector("#backgImg").style.display = 'none'
}
// 点击视频空白播放
let flag2 = true;
const clickVideo = () => {
  if (flag2) {
    video_.value.play()
    flag2 = false
  } else {
    video_.value.pause()
    flag2 = true
  }
}


let autoVideo = ref(null)
watch(navShow, () => {
  autoVideo.value.forEach(item => item.play())
})
</script>

<style lang="less" scoped>
@import url('../style/home.less');

.el-skeleton {
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.box_phone {
  display: none;
}

@media screen and (max-width:1023px) {
  .box {
    display: none;
  }

  .box_phone {
    display: block;
  }
}

/deep/.el-overlay {
  z-index: 999999999 !important;
}

.play_video_xq {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 700/@maxw;
}

.zytz_dialog {
  width: 100%;
  height: 700/@maxw;
  overflow: auto;
  margin-top: -30/@maxw;

  h1 {
    box-sizing: border-box;
    font-size: 32/@maxw;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #174994;
    border-bottom: 2/@maxw solid #174994;
    margin: 0 10/@maxw 0 0;
    padding: 0 0 20/@maxw 0;
  }

  nav {
    font-size: 22/@maxw;
  }
}

.zytz_dialog::-webkit-scrollbar {
  width: 5px;
  background-color: #355f9f30;
  border-radius: 20px;
  padding-top: 20px !important;
}

.zytz_dialog::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #1b5bbc97;
  border-radius: 20px;
  padding-top: 20px !important;
}

/deep/.el-dialog__headerbtn {
  right: 10/@maxw;
}
</style>
